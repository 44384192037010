import React, { lazy } from 'react'

const RequisitionManagementListing = lazy(() =>
  import('./RequisitionManagement/RequisitionManagementListing').then(
    module => ({
      default: module.RequisitionManagementListing,
    })
  )
)

const MenuListing = lazy(() =>
  import('./RequisitionManagement/CompanySetting/MenuListing').then(module => ({
    default: module.MenuListing,
  }))
)

const RequisitionApprovalPolicyAssignment = lazy(() =>
  import(
    './RequisitionManagement/CompanySetting/RequisitionApprovalPolicy/ApprovalPolicyAssignment'
  ).then(module => ({
    default: module.ApprovalPolicyAssignment,
  }))
)

const StaffMovementRequestList = lazy(() =>
  import(
    './RequisitionManagement/CompanySetting/StaffMovementRequestList/StaffMovementRequestList'
  ).then(module => ({
    default: module.StaffMovementRequestList,
  }))
)

const RequisitionAPAssignmentMenu = lazy(() =>
  import(
    './RequisitionManagement/CompanySetting/RequisitionApprovalPolicy/RequisitionAPAssignmentMenu'
  ).then(module => ({
    default: module.RequisitionAPAssignmentMenu,
  }))
)


// -----------------------------------------------------------------------------------------

const HomeRoutes = [
  {
    props: { exact: true, path: '/RequisitionManagementListing' },
    component: <RequisitionManagementListing />,
  },
  {
    props: {
      exact: true,
      path: '/RequisitionManagement/CompanySetting/MenuListing',
    },
    component: <MenuListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/RequisitionManagement/CompanySetting/MenuListing/requsitionapprovalassignment',
    },
    component: <RequisitionAPAssignmentMenu />,
  },
  {
    props: {
      exact: true,
      path:
        '/RequisitionManagement/CompanySetting/MenuListing/staffmovementrequestlist',
    },
    component: <StaffMovementRequestList />,
  },
  {
    props: {
      exact: true,
      path:
        '/RequisitionManagement/CompanySetting/MenuListing/requsitionapprovalassignment/cash',
    },
    component: <RequisitionApprovalPolicyAssignment type="Cash" />,
  },
  {
    props: {
      exact: true,
      path:
        '/RequisitionManagement/CompanySetting/MenuListing/requsitionapprovalassignment/asset',
    },
    component: <RequisitionApprovalPolicyAssignment type="Asset" />,
  },

  {
    props: {
      exact: true,
      path:
        '/RequisitionManagement/CompanySetting/MenuListing/requsitionapprovalassignment/others',
    },
    component: <RequisitionApprovalPolicyAssignment type="Others" />,
  },

  {
    props: {
      exact: true,
      path:
        '/RequisitionManagement/CompanySetting/MenuListing/requsitionapprovalassignment/travel',
    },
    component: <RequisitionApprovalPolicyAssignment type="Travel" />,
  },

  {
    props: {
      exact: true,
      path:
        '/RequisitionManagement/CompanySetting/MenuListing/requsitionapprovalassignment/staffmovement',
    },
    component: <RequisitionApprovalPolicyAssignment type="Staff Movement" />,
  },
]

export default HomeRoutes
