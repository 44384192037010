import React, { lazy } from 'react'

const RequisitionListing = lazy(() =>
  import('./RequisitionApplication/RequisitionListing').then(module => ({
    default: module.RequisitionListing
  }))
)

const RequisitionForm = lazy(() =>
  import('./RequisitionApplication/RequisitionForm').then(module => ({
    default: module.RequisitionForm
  }))
)

const RequisitionDetail = lazy(() =>
  import('./RequisitionApplication/RequisitionDetail').then(module => ({
    default: module.RequisitionDetail
  }))
)

const RequisitionManagementRoutes = [
  {
    props: { exact: true, path: '/RequisitionApplication/RequisitionListing' },
    component: <RequisitionListing />,
  },
  {
    props: { exact: true, path: '/RequisitionApplication/RequisitionForm/add' },
    component: <RequisitionForm mode='add' />,
  },
  {
    props: { exact: true, path: '/RequisitionApplication/RequisitionForm/edit/:RequisitionHeaderID' },
    component: <RequisitionForm mode='edit' />,
  },
  {
    props: { exact: true, path: '/RequisitionApplication/RequisitionDetail' },
    component: <RequisitionDetail />,
  },
]

export default RequisitionManagementRoutes